import firebase from "firebase";
require("firebase/firestore");
// Initialize Firebase
const config = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: "eastcruise-21.firebaseapp.com",
  databaseURL: "https://eastcruise-21.firebaseio.com",
  projectId: "eastcruise-21",
  storageBucket: "eastcruise-21.appspot.com",
  messagingSenderId: "1034402629109",
  appId: "1:1034402629109:web:2f1e405cdc9ab7b9814cbc",
  measurementId: "G-H0BJY6W2KW",
};

export const firebaseApp = firebase.initializeApp(config);
export const db = firebase.firestore();
export const decksRef = firebase.firestore().collection("decks");
export const roomsRef = firebase.firestore().collection("rooms");
export const usersRef = firebase.firestore().collection("users");
