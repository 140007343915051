import React from "react";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { FiAlertOctagon } from "react-icons/fi";

import "./alert.scss";

export default function Alert({ trigger, message, title, onClick }) {
  return (
    <Popup trigger={trigger} modal nested>
      {(close) => (
        <div className="Alert">
          <FiAlertOctagon className="Alert__icon" />
          <h2 className="Alert__title">{title}</h2>
          <h3 className="Alert__message">{message}</h3>
          <button className="Alert__button yes" onClick={onClick}>
            Confirm
          </button>
          <button className="Alert__button no" onClick={close}>
            Cancel
          </button>
        </div>
      )}
    </Popup>
  );
}
