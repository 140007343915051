import React, { Component } from "react";
import { roomsRef, usersRef } from "../utils/firestore";
import "./deckinfo.scss";

export default class DeckInfo extends Component {
  constructor() {
    super();
    this.state = {
      members: [],
      rooms: [],
    };
    this.formatNames = this.formatNames.bind(this);
    this.roomsOnDeck = this.roomsOnDeck.bind(this);
    this.membersForRoom = this.membersForRoom.bind(this);
    this.deckPlanLink = this.deckPlanLink.bind(this);
  }

  componentDidMount() {
    this.getData();
  }

  roomsOnDeck(allRooms) {
    let filtered = allRooms.filter(
      (room) => String(this.props.deck) === String(room).slice(0, -3)
    );
    return filtered;
  }

  formatNames(members) {
    let o = members.map((member) => member.name);
    return o.join(" · ");
  }

  membersForRoom(room) {
    let exp = new RegExp(String(room));
    let filtered = this.state.members.filter((member) => exp.test(member.room));
    return filtered;
  }

  deckPlanLink() {
    let num = parseInt(this.props.deck).toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
    return (
      <a
        href={`https://www.royalcaribbean.com/cruisingpower/content/shared_assets/svg/svg_r_QN_2029/IDP-DECK${num}.svg`}
        rel="noopener noreferrer"
        target="_blank"
        className="DeckInfo__header__button"
      >
        View Deck Map 
      </a>
    );
  }

  async getData() {
    await roomsRef.onSnapshot((docs) => {
      var allrooms = docs.docs.map((room) => {
        return room.id;
      });
      var rooms = this.roomsOnDeck(allrooms);

      rooms.map((room) => {
        return usersRef
          .where("room", "==", room)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((user) => {
              this.setState({
                members: this.state.members.concat(user.data()),
              });
            });
          });
      });
      this.setState({ rooms: rooms });
    });
  }

  render() {
    return (
      <div className="DeckInfo">
        <div className="DeckInfo__header">
          <h2>Deck {this.props.deck}</h2>
          {this.deckPlanLink()}
        </div>
        <div className="DeckInfo__rooms">
          {this.state.rooms
            .sort()
            .reverse()
            .map((room) => (
              <div className="DeckInfo__room" key={room}>
                <p className="DeckInfo__room__number">Room #{room}</p>
                <p>
                  {this.formatNames(this.membersForRoom(room))}
                </p>
              </div>
            ))}
        </div>
      </div>
    );
  }
}
