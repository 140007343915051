import React, { Component } from "react";
import { FcGoogle } from "react-icons/fc";
import { roomsRef, usersRef } from "../utils/firestore";
import Alert from "../alert/alert";
import Search from "../search/search";
import DeckInfo from "../deckinfo/deckinfo";

import "./dashboard.scss";

export default class Dashboard extends Component {
  constructor() {
    super();
    this.state = {
      myRoom: -1,
      rooms: [],
      search: "",
      searchDecks: [],
      decks: [],
      cruisers: 0,
    };
    this.handleChange = this.handleChange.bind(this);
    this.checkSearch = this.checkSearch.bind(this);
    this.filterDecks = this.filterDecks.bind(this);
  }

  componentDidMount() {
    this.getData();
  }

  filterDecks(rooms) {
    var decks = rooms.map((room) => {
      return String(room).slice(0, -3);
    });
    return [...new Set(decks)];
  }

  checkSearch() {
    if (this.state.search !== "") {
      usersRef.onSnapshot((docs) => {
        var a = docs.docs.filter((user) => {
          var exp = new RegExp(this.state.search, "i");
          return exp.test(user.data().name);
        });
        a = a.map((user) => user.data().room);
        this.setState({
          decks: this.filterDecks(a),
        });
      });
    } else {
      this.setState({
        decks: this.filterDecks(this.state.rooms),
      });
    }
  }

  handleChange(updater, e) {
    this.setState(updater(e.target.value), () => {
      this.checkSearch();
      this.filterDecks(this.state.rooms);
    });
  }

  async getData() {
    roomsRef.onSnapshot((docs) => {
      var allrooms = docs.docs.map((room) => {
        return room.id;
      });
      usersRef
        .where("email", "==", this.props.user.email)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((user) => {
            this.setState({ myRoom: user.data().room });
          });
        });
      usersRef.get().then((snap) => {
        this.setState({ cruisers: snap.size });
      });
      this.setState({
        rooms: allrooms,
        decks: this.filterDecks(allrooms),
      });
    });
  }

  formatName(name) {
    var re = /^([\w]+\s\w)/g;
    return name.match(re) + ".";
  }

  render() {
    return (
      <div className="Dashboard">
        <h1 className="Dashboard__title">🤡 Cruise Rooms</h1>
        <div className="Dashboard__container">
          <div className="Dashboard__container__header">
            <h3>Welcome, {this.formatName(this.props.user.displayName)}</h3>
            <h3>
              Room {this.state.myRoom === -1 ? "#?" : `#${this.state.myRoom}`}
            </h3>
          </div>
          <Search handler={this.handleChange} />
          {this.state.decks.length ? (
            this.state.decks
              .sort((a, b) => b - a)
              .map((deck) => <DeckInfo key={deck} deck={deck} />)
          ) : (
            <h2 style={{ marginBottom: "20px" }}>Friend not here :(</h2>
          )}
          <div>
            <h5
              style={{
                padding: "0.1em 0.7em",
                margin: "0",
                marginBottom: "1em",
                color: "#818181",
                borderRadius: "6px",
                border: "1px #818181 solid",
              }}
            >
              Dragons Counter: {this.state.cruisers}
            </h5>
          </div>
          <Alert
            trigger={
              <button className="Dashboard__button google">
                <FcGoogle className="Dashboard__button__icon" />
                <h4 className="Dashboard__button__text__google">Sign Out</h4>
              </button>
            }
            onClick={this.props.signOut}
            title="Signing Out"
            message="Are you sure you want to sign out?"
          />
          <p className="Dashboard__footer">
            Hey everyone, this site is just for informational purposes as I know
            a bunch of us are going on the cruise after exams. Feel free to use
            this as a resource if it helps your cruise planning, but{" "}
            <strong>
              please be responsible and follow the Covid-19 safety measures
              aboard the ship and otherwise.
            </strong>{" "}
          </p>
        </div>
        <a
          href="https://akshatbisht.com"
          rel="noopener noreferrer"
          target="_blank"
          className="Dashboard__credit"
        >
          By Akshat B.
        </a>
      </div>
    );
  }
}
