import React from "react";
import { GoSearch } from "react-icons/go";

import "./search.scss";

export default function Search({ handler }) {
  function updateSearch(s) {
    return { search: s };
  }
  return (
    <form className="Search">
      <input
        type="text"
        name="search"
        className="Search__input"
        onChange={(e) => {
          handler(updateSearch, e);
        }}
        placeholder="Find your friends"
      ></input>
      <GoSearch className="Search__icon" />
    </form>
  );
}
