import React, { Component } from "react";
import { db } from "../utils/firestore";

import "./edit.scss";

export default class Edit extends Component {
  constructor() {
    super();
    this.state = {
      room: "",
      name1: "",
      email1: "",
      name2: "",
      email2: "",
      name3: "",
      email3: "",
      name4: "",
      email4: "",
    };
    this.updateInput = this.updateInput.bind(this);
    this.addRoom = this.addRoom.bind(this);
  }

  updateInput(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  roomExists(room) {
    db.collection("rooms")
      .doc(room)
      .get()
      .then((doc) => {
        if (!doc.exists) {
          console.log("rfalse");
          return false;
        } else {
          console.log("rtrue");
          return true;
        }
      });
  }

  addRoom(e) {
    e.preventDefault();
    if (this.roomExists(this.state.room)) {
      window.alert(`Creation Error: ${this.state.room} already exists!`);
      return false;
    } else {
      db.collection("rooms")
        .doc(this.state.room)
        .set({
          number: this.state.room,
        })
        .catch((error) => {
          console.error("Error writing document: ", error);
        });
      if (this.state.name1 !== "") {
        db.collection("users")
          .doc(this.state.name1)
          .set({
            name: String(this.state.name1),
            email: String(this.state.email1),
            room: String(this.state.room),
          })
          .catch((error) => {
            console.error("Error writing name: ", error);
          });
      }
      if (this.state.name2 !== "") {
        db.collection("users")
          .doc(this.state.name2)
          .set({
            name: String(this.state.name2),
            email: String(this.state.email2),
            room: String(this.state.room),
          })
          .catch((error) => {
            console.error("Error writing name: ", error);
          });
      }
      if (this.state.name3 !== "") {
        db.collection("users")
          .doc(this.state.name3)
          .set({
            name: String(this.state.name3),
            email: String(this.state.email3),
            room: String(this.state.room),
          })
          .catch((error) => {
            console.error("Error writing name: ", error);
          });
      }
      if (this.state.name4 !== "") {
        db.collection("users")
          .doc(this.state.name4)
          .set({
            name: String(this.state.name4),
            email: String(this.state.email4),
            room: String(this.state.room),
          })
          .catch((error) => {
            console.error("Error writing name: ", error);
          });
      }
      this.setState({
        room: "",
        name1: "",
        email1: "",
        name2: "",
        email2: "",
        name3: "",
        email3: "",
        name4: "",
        email4: "",
      });
      return true;
    }
  }

  render() {
    return (
      <div className="Edit">
        <h1 className="Edit__title">room admin stuff</h1>
        <a href="/" style={{ marginBottom: "20px", fontSize: "15px" }}>
          homeeeepage
        </a>
        <div className="Edit__container">
          <form onSubmit={(e) => this.addRoom(e)}>
            <div>
              <label>room num</label>
              <input
                name="room"
                type="text"
                onChange={this.updateInput}
                value={this.state.room}
                required={true}
              />
            </div>
            <label>name1</label>
            <input
              name="name1"
              type="text"
              onChange={this.updateInput}
              value={this.state.name1}
              required={true}
            />
            <label>email1</label>
            <input
              name="email1"
              type="text"
              onChange={this.updateInput}
              value={this.state.email1}
              required={false}
            />

            <label>name2</label>
            <input
              name="name2"
              type="text"
              onChange={this.updateInput}
              value={this.state.name2}
              required={false}
            />
            <label>email2</label>
            <input
              name="email2"
              type="text"
              onChange={this.updateInput}
              value={this.state.email2}
              required={false}
            />

            <label>name3</label>
            <input
              name="name3"
              type="text"
              onChange={this.updateInput}
              value={this.state.name3}
              required={false}
            />
            <label>email3</label>
            <input
              name="email3"
              type="text"
              onChange={this.updateInput}
              value={this.state.email3}
              required={false}
            />

            <label>name4</label>
            <input
              name="name4"
              type="text"
              onChange={this.updateInput}
              value={this.state.name4}
              required={false}
            />
            <label>email4</label>
            <input
              name="email4"
              type="text"
              onChange={this.updateInput}
              value={this.state.email4}
              required={false}
            />

            <button type="submit" value="Submit">
              Add
            </button>
          </form>
        </div>
      </div>
    );
  }
}
