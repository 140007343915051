import React from "react";
import { FcGoogle } from "react-icons/fc";
import "./login.scss";

export default function Login({ signInWithGoogle }) {
  return (
    <section className="Login">
      <h1 className="Login__title">🤡 Cruise Portal</h1>

      <div className="Login__button" onClick={signInWithGoogle}>
        <FcGoogle className="Login__button__icon" />
        <h4 className="Login__button__text">RIP</h4>
      </div>
    </section>
  );
}
