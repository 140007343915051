import React, { Component } from "react";
import withFirebaseAuth from "react-with-firebase-auth";
import firebase from "firebase/app";
import Login from "../components/login/login";
import "firebase/auth";
import { firebaseApp, db } from "../components/utils/firestore";
import Dashboard from "../components/dashboard/dashboard";

const firebaseAppAuth = firebaseApp.auth();
const providers = {
  googleProvider: new firebase.auth.GoogleAuthProvider(),
};

class Home extends Component {
  constructor() {
    super();
    this.state = {
      signedIn: false,
    };
    this.authUser = this.authUser.bind(this);
  }

  updateSignedIn(val, onComplete = null) {
    this.setState(
      { signedIn: val },
      onComplete === null ? () => null : () => onComplete()
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.user !== prevProps.user) {
      this.authUser(
        this.props.user,
        this.updateSignedIn.bind(this),
        this.props.signOut
      );
    }
  }

  authUser(user, updater, signOut) {
    if (user == null) {
      return null;
    }
    let dbRef = db.collection("users");
    dbRef
      .where("email", "==", user.email)
      .get()
      .then(function (querySnapshot) {
        // For now allow all GAPPS email addresses.
        if (querySnapshot.docs.length === 0) {
          // var exp = new RegExp("gapps.uwcsea.edu.sg");
          // if (!exp.test(user.email)) {
          updater(false, () => {
            signOut();
            window.alert(
              "Sign in unauthorised 😱 Contact me so I can enable your account."
            );
          });
        } else {
          updater(true);
        }
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });
  }

  logOut(signOut) {
    this.updateSignedIn(false, () => {
      signOut();
    });
  }

  render() {
    const { user, signOut, signInWithGoogle } = this.props;
    return (
      <div className="outer">
        {this.state.signedIn ? (
          <Dashboard user={user} signOut={this.logOut.bind(this, signOut)} />
        ) : (
          <Login signInWithGoogle={signInWithGoogle} />
        )}
      </div>
    );
  }
}

export default withFirebaseAuth({
  providers,
  firebaseAppAuth,
})(Home);
