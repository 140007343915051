import "./App.scss";
import Home from "./page/home";
import Admin from "./page/admin";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/admin" component={Admin} />
        <Route path="/" component={Home} />
        {/* 404 page not working??? */}
        <Route component={Home} />
      </Switch>
    </Router>
  );
}

export default App;
